import Box from '@mui/material/Box'
import * as React from 'react'
import { Outlet } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../_helpers/hooks'
import { newBookingData } from '../../slices/newBookingSlice'
import { userDetails, userState } from '../../slices/userSlice'
import CustomerDrawer from './Drawer'
import { default as CustomerHeader } from './header'
import { switchBusinessList } from '../../slices/businessSlice'
import { getLanguages } from '../../slices/translationSlice'

export default function CustomerLayout() {
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  const handleDrawerOnPopoverClick = () => {
    setMobileOpen(false)
  }
  const dispatch = useAppDispatch()
  const userInformation = useAppSelector(userState)
  const { userData } = userInformation
  React.useLayoutEffect(() => {
    dispatch(userDetails())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const newBookingState = useAppSelector(newBookingData)
  const { drawerMode } = newBookingState

  React.useEffect(() => {
    if (drawerMode) {
      document.body.classList.remove('add-scroll')
      document.body.classList.add('no-scroll')
    } else {
      document.body.classList.remove('no-scroll')
      document.body.classList.add('add-scroll')
    }
  }, [drawerMode])

  React.useEffect(() => {
    dispatch(getLanguages())
    dispatch(switchBusinessList())
  }, [dispatch])

  return (
    <>
      <CustomerHeader
        handleDrawerOnPopoverClick={handleDrawerOnPopoverClick}
        handleDrawerToggle={handleDrawerToggle}
        userData={userData}
      >
        <Box component='nav'>
          <CustomerDrawer
            mobileOpen={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
            userData={userData}
            handleDrawerOnPopoverClick={handleDrawerOnPopoverClick}
          />
        </Box>
      </CustomerHeader>

      <Box sx={{ overflowX: 'hidden' }}>
        <Outlet />
      </Box>
    </>
  )
}

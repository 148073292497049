import Box from '@mui/material/Box'
import * as React from 'react'
import { Outlet } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../_helpers/hooks'
import PricingAlertModal from '../../components/pricingAlert'
import PlanLimitExceededAlert from '../../components/pricingAlert/planLimitExceeded'
import NewBookingDrawer from '../../features/newBooking'
import { closeDrawer, newBookingData } from '../../slices/newBookingSlice'
import {
  pricingData,
  setPlanLimitExceededAlert,
  setPricingAlertModal,
} from '../../slices/pricingSlice'
import { userState } from '../../slices/userSlice'
import AdminDrawer from './Drawer'
import { default as AdminHeader } from './header'
import { switchBusinessList } from '../../slices/businessSlice'
import { getLanguages } from '../../slices/translationSlice'

export default function AdminLayout() {
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  const handleDrawerOnPopoverClick = () => {
    setMobileOpen(false)
  }
  const dispatch = useAppDispatch()
  const userInformation = useAppSelector(userState)
  const { userData } = userInformation
  const newBookingState = useAppSelector(newBookingData)
  const { drawerMode } = newBookingState
  const pricingState = useAppSelector(pricingData)
  const {
    showPricingAlert,
    pricingAlertContent,
    showPlanLimitExceededAlert,
    planLimitExceededContent,
  } = pricingState

  React.useEffect(() => {
    dispatch(getLanguages())
    dispatch(switchBusinessList())
  }, [dispatch])

  React.useEffect(() => {
    if (drawerMode) {
      document.body.classList.remove('add-scroll')
      document.body.classList.add('no-scroll')
    } else {
      document.body.classList.remove('no-scroll')
      document.body.classList.add('add-scroll')
    }
  }, [drawerMode])

  const closeNewBookingDrawer = () => {
    dispatch(closeDrawer())
  }

  return (
    <>
      <AdminHeader
        handleDrawerOnPopoverClick={handleDrawerOnPopoverClick}
        handleDrawerToggle={handleDrawerToggle}
        userData={userData}
      >
        <Box component='nav'>
          <AdminDrawer
            mobileOpen={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
            userData={userData}
            handleDrawerOnPopoverClick={handleDrawerOnPopoverClick}
          />
        </Box>
      </AdminHeader>

      <Box sx={{ overflowX: 'hidden', overflowY: 'hidden' }}>
        <NewBookingDrawer drawerStatus={drawerMode} updateDrawer={closeNewBookingDrawer} />
        <Outlet />
      </Box>
      <PricingAlertModal
        showAlert={showPricingAlert}
        content={pricingAlertContent}
        onAlertClose={() => dispatch(setPricingAlertModal({ status: false }))}
      />
      <PlanLimitExceededAlert
        showAlert={showPlanLimitExceededAlert}
        content={planLimitExceededContent}
        onAlertClose={() => dispatch(setPlanLimitExceededAlert({ status: false }))}
      />
    </>
  )
}
